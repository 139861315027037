.defaultState {
  @apply text capitalize;
}

.active {
  @apply capitalize;
  color: var(--active-color);
}

.accepted {
  color: var(--active-color);
}

.pending {
  color: var(--pending-color);
}

.canceled {
  @apply text-secondary;
}
