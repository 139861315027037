.sidebar {
  @apply py-lg sm:p-[var(--gutter)] lg:pt-lg;
  @apply top-[var(--topbar-height)] bottom-0;
  @apply fixed max-h-full w-full -right-full overflow-y-auto;

  @apply bg-secondary;

  @apply border-none;
  @apply border-solid;
  @apply border-[color:var(--alias-color-background-base)];
  @apply sm:border-l sm:border-l-weak lg:border-l-transparent;

  @apply ease-in-out duration-200;
  @apply transition-[width,right];
}

.sidebar span {
  @apply cursor-default;
}

/**
 * Sidebar open state
 */
.sidebarOpen {
  @apply z-[49];
  @apply right-0 box-content;
  @apply sm:w-[var(--sidecolumn-size)]; /* Sidebar open width */
}

/**
 * Sidebar always open on large screens
 */
.sidebar, .sidebarOpen {
  @apply lg:top-auto lg:w-auto lg:right-auto;
  @apply lg:static;
  @apply lg:px-0;
  @apply lg:box-border;

  @apply lg:border-l-0 lg:border-t-0;
  @apply lg:transition-none;
}

.sidebar .mainNav {
  @apply opacity-0;
  @apply -mx-xs sm:-mx-lg lg:mr-0 lg:-mx-[1px] xl:mr-[var(--gutter)] xl:ml-0;
  @apply transition-[opacity] lg:transition-none;
}
.sidebarOpen .mainNav {
  @apply opacity-100;
}

.selects {
  @apply px-md sm:px-0 lg:px-[var(--gutter)] xl:pl-0;
}

.userMenu button {
  @apply min-w-[8rem];
  padding: calc(var(--spacing-user-menu-padding-block) - 1px) calc(var(--spacing-user-menu-padding-inline) - 1px);
}

.sidenavUserMenu, .lbidHelperText {
  @apply lg:hidden;
}

.lbidHelperText {
  @apply mt-md mb-xs;
}

.selects > div:last-child {
  @apply mb-lg;
}
