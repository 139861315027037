
.aside {
  @apply mt-2 pt-2 sm:px-2;
  @apply px-0;
  @apply border-t;
  border-color: var(--alias-color-border-default);
}
@media only screen and (min-width: 1280px) {
  .aside {
    @apply m-0 p-0 bg-transparent;
    @apply border-none;
  }
  .configure .asidePanel {
    top: 0;
  }
}

@media only screen and (max-width: 1279px) {
  .configure .asidePanel {
    @apply px-0;
  }
  .configure .asidePanel > div > div {
    @apply px-0;
  }
  footer.shoppingCart {
    @apply hidden;
  }
}
.aside .asideToggle {
  @apply bg-transparent;
  @apply pl-0;
}
.aside .asideToggle > span:last-child {
  @apply right-0;
}
.closedLabel {
  @apply flex justify-between;
}
.shoppingCart h6 {
  @apply font-bold;
}
.priceBreakdown {
  @apply flex justify-between;
}
.priceBreakdown.total {
  @apply mt-2 pt-2;
  @apply border-t;
  @apply text-accent;
}
.taxDescription {
  font-size: .667rem;
  line-height: 0.875rem;
  color: var(--alias-color-text-tertiary);
}
footer.shoppingCart {
  @apply mt-0;
}
