.billing {
  @apply flex flex-col justify-between items-start h-full;
}

.buttons {
  @apply flex flex-col gap-4 w-full mb-6;
}

.billingLink {
  @apply text-brand-primary;
  margin-left: 18px;
}

.companyName {
  @apply font-bold;
}
