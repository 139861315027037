.thankYouFooter > :is(button, a, span) {
  @apply inline-block list-none;
}

.thankYouFooter > :is(a, span)::after {
  @apply inline-block mx-2 h-4 align-middle;
  content: " ";
  border-left: 1px solid var(--alias-color-border-default);
}

.thankYouFooter button {
  @apply px-1;
  @apply text-sm underline;
  @apply focus:outline-none;
  color: var(--link-color);
  font-family: var(--typography-font-primary);
}
