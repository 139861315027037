.ssoInfoSection {
  @apply py-lg mb-2xl;
}
.ssoInfo {
  @apply text-tertiary;
  @apply mt-6 max-w-full;
}
.ssoInfo > dl {
  @apply flex items-center;
}
.ssoInfo > dt {
  @apply mt-md;
}
.ssoInfo > dd {
  @apply mb-md;
  @apply flex items-center;
}
.ssoInfo > .verifiedDomainDD {
  @apply mb-0;
}
.ssoInfoText {
  @apply truncate min-w-0 mr-md;
}
.buttonBar {
  @apply pt-0 sm:w-max px-0 mt-sm;
}
.ssoTypeSelection {
  @apply mb-2xl md:grid md:grid-cols-2 md:gap-md;
}
.urlPrefix {
  @apply md:grid md:grid-cols-2 md:gap-md;
}
