.content {
  @apply p-2xl pb-lg;
}

.lbidSelect {
  @apply mt-2xl mx-auto sm:mx-10;
}

.createLbidButton {
  @apply sm:w-max mx-auto;
}

.resetItem > div:last-of-type {
  @apply rotate-0;
}

button:global(.lds-select--select-button).resetItem {
  @apply justify-between;
}

.optionsReset {
  @apply p-0;
}

.resetItem > div:first-of-type {
  @apply ml-sm;
}
