.currentUser {
  @apply block text-sm text-center;
}

.b {
  @apply text-accent font-bold;
}

.logout {
  @apply pl-xxs;
  @apply focus:outline-none;
  color: var(--link-color);
}
