.whatNextList {
  @apply mt-2.5 list-none;
}

.checkItem {
  @apply flex relative gap-sm;
}

.checkItem:not(:last-child)::before {
  @apply content-[''];
  @apply absolute h-full left-2 -ml-[0.5px];
  @apply border-l border-[color:var(--alias-color-text-secondary)];
}

.checkItem > span {
  @apply z-[1]; /* "::before line" should be below icon and text */
}

.checkMark {
  @apply flex justify-center items-center;
  @apply w-4 h-4 p-0.25;
  @apply rounded-full;
  @apply bg-[color:var(--alias-color-text-secondary)];
}

.checkItemLabel {
  @apply -mt-1 mb-1.25;
  @apply text text-sm no-underline;
  @apply cursor-pointer;
}

.checked .checkItemLabel {
  @apply text-accent;
}

.checked a.checkItemLabel:hover,
a.checkItemLabel:hover {
  @apply text-brand-primary underline;
}

/**
 * Check item checked state
 */
.checkItem.checked .checkMark {
  @apply bg-ok;
}

.checkItem.checked .checkItemLabel {
  @apply text-accent;
}

.checkItem:has(+ .checked):not(:last-child)::before {
  @apply border-ok;
}

.checkItem.checked .checkMark svg path {
  @apply fill-white;
}
