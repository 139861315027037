.avatar {
  @apply w-10 h-10;
  @apply grid place-items-center;
  @apply cursor-default select-none;
  @apply overflow-hidden;
  @apply rounded;
}

.avatar.md {
  @apply w-40 h-40;
  @apply grid place-items-center;
  @apply cursor-default select-none;
  @apply overflow-hidden;
  border-radius: 50%;
}

.avatarText {
  @apply text-accent;
}

.avatar.md .avatarText {
  @apply text-3xl;
}
