.errorNotification {
  @apply text-sm mb-5;
}

.assignBusinessSubscriptionModalContent > header h3 {
  @apply my-3;
}

.assignBusinessSubscriptionModalPanel {
  @apply p-8;
}

.chooseLensSubscriptionLabel {
  @apply block uppercase text-left font-bold text-sm;
  @apply mb-2;
  padding-left: 2px;
  font-family: var(--typography-font-primary);
  color: var(--input-label-color);
}

.assigneeEmailTextareaLabel {
  composes: chooseLensSubscriptionLabel;
}

.selectBusinessSubscriptionOptions {
  @apply !max-h-40;
}
.selectBusinessSubscription {
  @apply mb-10;
}

.newSubscriptionButton {
  @apply w-full px-2 py-4 mt-1;
  @apply text-sm border-t;
  @apply rounded;
  color: #88929d;
  border-color: var(--alias-color-border-default);
}

.newSubscriptionButton:hover {
  @apply text-accent;
  @apply bg-base;
}

.assigneeEmailTextarea {
  @apply w-full px-4 py-4;
  @apply focus:outline-none;
  @apply border rounded;
  border-color: var(--alias-color-border-default);
  background-color: var(--color-background-input-default);
  color: var(--color-font-input-default);
  min-height: 8rem;
}
.assigneeEmailTextarea:focus {
  color: var(--color-font-input-focus);
}
.assigneeEmailTextarea::placeholder {
  @apply text-sm;
  opacity: var(--opacity-disabled);
}

.assigneeEmailsErrorMessage {
  @apply text-danger text-right;
  @apply text-sm;
  @apply mt-2;
}

.assigneeEmailsMessage {
  @apply text-tertiary text-right;
  @apply text-sm;
  @apply mt-2;
}

.footerButtonbar {
  @apply w-full grid grid-cols-2 gap-x-8;
}
