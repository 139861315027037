.autoJoinSwitchWrapper {
  @apply grid grid-flow-col gap-5;
}
.switch {
  @apply self-start place-self-end;
}

.link {
  @apply underline;
  @apply focus:outline-none;
  color: var(--link-color);
  font-family: var(--typography-font-primary);
}
