h3.tableTitle {
  @apply text-sm font-bold uppercase mb-4 text-secondary;
}

.tableSubTitle {
  @apply block place-self-start mb-2 md:mb-0.5;
  @apply text-sm text-secondary;
}

.email {
  @apply text;
}

.verified {
  color: var(--verified-color);
}

.unverified {
  color: var(--unverified-color);
}

.primaryBadge {
  @apply uppercase px-2 mx-3 text-xs bg-tertiary text-tertiary;
  border-radius: 0.28rem;
}

.contextMenuHeader {
  @apply flex flex-1;
  justify-content: flex-end;
  padding-right: 15px;
}

.menuContainer {
  padding-right: 15px;
}
