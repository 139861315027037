.updateSubscription {
  @apply relative;
}
/* Layout & styles */
.updateSubscription section {
  @apply py-4 sm:px-2;
  @apply border-b;
  border-color: var(--alias-color-border-default);
}
.newSeats {
  border-bottom: 0;
}
.planInfo, .billingCycle {
  @apply flex flex-col p-0;
}
.planInfo > div {
  @apply my-3;
}
.planInfo > div:first-child {
  @apply mt-0;
}
.planInfo > div:last-child {
  @apply mb-3;
}
.planInfo:last-child {
  @apply mb-0 border-none;
}

/* Desktop layout */
.planInfo, .billingCycle {
  @apply sm:p-2;
}
.billingCycle {
  @apply sm:pt-6;
}
.planInfo {
  @apply sm:flex-row;
}
.planInfo > div {
  @apply sm:w-6/12;
}
.planInfo > div:first-child {
  @apply sm:mt-1 sm:mr-3;
}
.planInfo > div:last-child {
  @apply sm:mt-1 sm:mb-6 sm:ml-3;
}
.licenseImage {
  @apply sm:self-start;
  @apply rounded-none sm:rounded-md;
}

/* Seat selector */
.seatCount {
  @apply mt-2;
}
.seatCountContent {
  @apply flex flex-row flex-wrap;
  @apply mt-2;
}
.seatCount > p {
  @apply mb-0 mt-1;
}
.seatCountButton, .seatCountInput {
  @apply px-4 py-2 h-10;
  @apply cursor-pointer;
  @apply border border-transparent;
  @apply text-base;
  @apply bg-transparent;
  @apply bg-secondary;
  border-color: var(--alias-color-border-default);
  font-family: var(--typography-font-primary);
  color: var(--color-font-input-default);
  background-color: var(--color-background-input-default);
}
.seatCount .seatCountInput {
  @apply mb-0 w-16 pl-2 pr-1;
}
.pricePerSeat {
  @apply mb-0;
  @apply whitespace-nowrap sm:whitespace-normal;
  @apply flex-grow sm:text-right;
  flex-basis: 100%;
  @apply sm:flex-auto;
  @apply mt-2 sm:mt-0;
}
.pricePerSeat > span {
  @apply inline-block ml-1 sm:block;
}
.pricePerSeat span:first-child {
  @apply text-accent;
}
.highLight {
  @apply text-accent;
}
.assignedSeats {
  @apply uppercase;
}
h2.configureHeader {
  @apply my-0;
}
.currentSubscription {
  @apply flex justify-between;
  @apply mt-1 pb-1;
}
