.licenseClaim {
  @apply border border-ok p-2 overflow-hidden mb-8;
}

.home footer {
  @apply block m-6;
}

h3.title {
  @apply mb-3 uppercase text-sm text-left font-semibold;
}

.subscriptions {
  @apply flex flex-col p-6 bg-base;
}

.welcomeTitle {
  @apply text-base text-accent font-bold mb-3 mt-2;
}

.offlineButtonBar {
  @apply px-0 pt-0;
}
.ssoNotification {
  @apply items-center;
  @apply mb-md;
}
