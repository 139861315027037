
.pageAction {
  @apply mt-xl pb-sm;
}

.pageAction > button, .pageAction > a {
  @apply min-w-[var(--spacing-40)];
}
.pageAction > button {
  @apply w-full sm:w-auto;
}
.pageAction > a {
  @apply w-full sm:w-max;
}

