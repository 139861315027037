.emailLink {
  @apply no-underline hover:underline text not-italic;
}
.subscriptionHeader {
  @apply sm:pl-5;
}

td:has(> div.subscriptionPlanNameIdWrapper) {
  @apply sm:pl-8;
}

.stopIcon {
  @apply -ml-7 mt-1 absolute;
}

.planName {
  @apply text block;
}

.id {
  @apply uppercase;
  @apply text-secondary;
}
