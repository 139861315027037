.actions {
  @apply p-0 md:my-xs flex gap-sm justify-start justify-items-stretch;
}

.end {
  @apply justify-end
}

.actions :global(.lds-button) {
  @apply m-0 w-full md:w-auto;
}

table > tbody tr:first-of-type .actions {
  @apply md:mt-0;
}

table > tbody tr:last-of-type .actions {
  @apply md:mb-0;
}
