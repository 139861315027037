.childrenTable {
  @apply flex flex-col lg:min-w-full;
}

.childrenTable .tableTitle {
  @apply my-sm;
  @apply place-self-start;
}

.tableSubTitle {
  @apply block place-self-start mb-2 md:mb-0.5;
  @apply text-sm text-gray-400;
}

.tableTab {
  @apply md:grid mb-3;
}

.tableTabButtonBar {
  @apply place-self-end md:-mt-7 lg:-mt-8;
}

.buttonBar {
  @apply sm:w-max px-0;
}

.helpText {
  @apply text-xs text-gray-400;
}
