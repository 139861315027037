/* Temporary fixes for anchor tag styles */
a.routerButton:global(.lds-button) {
  text-decoration: none;
  color: var(--color-font-button-secondary-base);
}
a.routerButton:global(.lds-button.lds-button--discreet) {
  color: var(--color-font-button-secondary-discreet);
}
a.routerButton:global(.lds-button.primary) {
  color: var(--color-font-button-primary-base);
}
a.routerButton:global(.lds-button.primary.lds-button--discreet) {
  color: var(--color-font-button-primary-discreet);
}
a.routerButton:global(.lds-button).ok {
  color: var(--color-font-button-ok-base);
}
a.routerButton:global(.lds-button.lds-button--discreet).ok {
  color: var(--color-font-button-ok-discreet);
}
a.routerButton:global(.lds-button).caution {
  color: var(--color-font-button-caution-base);
}
a.routerButton:global(.lds-button.lds-button--discreet).caution {
  color: var(--color-font-button-caution-discreet);
}
a.routerButton:global(.lds-button).danger {
  color: var(--color-font-button-danger-base);
}
a.routerButton:global(.lds-button.lds-button--discreet).danger {
  color: var(--color-font-button-danger-discreet);
}
a.routerButton > span:global(.lds-button--label) {
  @apply align-sub;
}
a.routerButton:global(.lds-button--xs) > span:global(.lds-button--label) {
  @apply align-baseline;
}
