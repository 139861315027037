.openModalButton {
  @apply w-full py-5;
  @apply text-xs;
  @apply border-t border-weak;
  color: #88929d;
}

.openModalButton:hover {
  @apply text-accent;
  background-color: var(--link-bg-color);
}
