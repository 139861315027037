.featureList .title {
  @apply text-sm uppercase font-semibold mt-0 mb-1;
}

.featureList li {
  @apply flex gap-1 mb-2 leading-4;
}

.featureList li:before {
  content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSIjMzM4YWNjIj48cGF0aCBkPSJNOSAxNi4xN0w0LjgzIDEybC0xLjQyIDEuNDFMOSAxOUwyMSA3bC0xLjQxLTEuNDFMOSAxNi4xN3oiLz48L3N2Zz4=');
  line-height: 0;
}
