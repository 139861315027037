.form, .name {
  @apply sm:grid sm:grid-cols-2 sm:gap-6;
}

.serverResponseMessage {
  @apply mb-lg;
}

.communicationPreferences {
  @apply mt-lg;
}

.disclaimer {
  @apply text-sm;
}
