.errorNotification {
  @apply text-sm mb-5;
}

.inviteAdminModalContent > header h3 {
  @apply my-3;
}

.emailTextareaLabel {
  @apply block uppercase text-left font-bold text-sm;
  @apply mb-2;
  padding-left: 2px;
  font-family: var(--typography-font-primary);
  color: var(--input-label-color);
}

.emailTextarea {
  @apply w-full px-4 py-4;
  @apply focus:outline-none;
  @apply border rounded;
  border-color: var(--alias-color-border-default);
  background-color: var(--color-background-input-default);
  color: var(--color-font-input-default);
  min-height: 8rem;
}
.emailTextarea:focus {
  color: var(--color-font-input-focus);
}
.emailTextarea::placeholder {
  @apply text-sm;
  opacity: var(--opacity-disabled);
}

.emailsErrorMessage {
  @apply text-danger text-right;
  @apply text-sm;
  @apply mt-2;
}

.emailsMessage {
  @apply text-tertiary text-right;
  @apply text-sm;
  @apply mt-2;
}
