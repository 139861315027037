
.layout {
  @apply grid grid-rows-[auto,1fr,auto];
  @apply min-h-screen;
}

.main {
  @apply mt-[var(--main-top-margin)];
  @apply lg:mt-0;
  @apply px-[var(--gutter)] pt-3 lg:pt-7;
  @apply lg:border-l lg:border-r border-weak;
}

/**
 * Show topbar only on mobile & tablet
 */
.topbar {
  @apply lg:hidden fixed top-0 z-50;
  @apply h-[var(--topbar-height)];
}

/**
 * Hide username on tiny screens
 */
.lbidUserName {
  @apply text;
  @apply hidden sm:inline;
}

/**
 * Show header only on desktop
 */
.header {
  @apply w-full z-50;
  @apply h-[var(--topbar-height)];
  
  @apply sticky top-0;

  @apply hidden lg:block;
  
  @apply bg-base;
  @apply text-accent;
  @apply border-b border-b-weak;
}

.threeColumns {
  @apply grow self-start w-full;
  @apply min-h-full;
  @apply lg:max-w-[var(--layout-max-width)] lg:m-auto;
  @apply lg:grid lg:grid-cols-[var(--sidecolumn-size)_1fr_var(--sidecolumn-size)] lg:grid-rows-[auto];
}
