.accountHierarchy {
  @apply text;
}

.accountHierarchy header {
  @apply text text-base;
  @apply lg:text-sm;
  @apply min-w-full mb-8;
}

.accountHierarchy header p, .accountHierarchy section p {
  @apply leading-normal;
}

.accountHierarchy header h2.welcome {
  @apply mt-sm mb-lg;
}

.accountHierarchy section {
  @apply mb-14;
}

.enterpriseAccNotification {
  @apply mt-lg mb-2xl;
}

.disabledSection {
  @apply opacity-50;
  @apply pointer-events-none;
}
