.asideBlock {
  @apply p-4 mt-0;
  @apply sm:rounded-lg;
  @apply text-xs;
}
div.panel.header {
  @apply mb-0 mt-4 p-4 font-semibold;
  @apply rounded-none;
}
div.panel.header h5 {
  @apply m-0;
  @apply text-xs leading-4;
  @apply text-accent;
}
div.panel.content {
  @apply p-4 mb-0
}
footer.panel {
  @apply mb-0 px-4;
}
div.panel.content > p:last-child {
  @apply mb-0;
}
.asideBlock > div:first-child > .header {
  @apply mt-0;
}

/* Accordion toggle */
.toggle {
  @apply pl-4 sm:pl-6 pr-10 py-4 w-full relative text-left;
  @apply text-accent;
  @apply sm:rounded-lg mb-1 sm:mb-2;
  @apply bg-brand-primary;
  border-color: var(--alias-color-border-default);
}
.toggle > span.icon {
  @apply absolute right-4 sm:right-6 inset-y-4;
}
.toggle.open {
  @apply rounded-t-none;
  @apply border-t;
  @apply bg-block-footer;
  border-color: var(--alias-color-border-default);
}

/* Mobile */
@media only screen and (max-width: 1279px) {
  .asideBlock {
    @apply px-0 sm:px-2 py-2;
  }
  .asideBlock.open {
    @apply rounded-b-none;
  }
  .asideBlock > div > .header {
    @apply m-0 py-2;
    @apply text-left;
  }
  .asideBlock > div > div {
    @apply bg-transparent;
  }
}

/* Desktop */
@media only screen and (min-width: 1280px) {
  .transition {
    /* We need to use important to override inline transition styles  */
    opacity: 1 !important;
    height: auto !important;
  }
  .toggle {
    @apply hidden;
  }
  .asideBlock {
    @apply p-0;
    @apply absolute w-72 -right-72 top-0 pl-4;
  }
  div.panel.header {
    @apply rounded-t-xl;
  }
  div.panel.content:last-child {
    @apply rounded-b-xl;
  }
}
