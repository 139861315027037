.header {
  @apply h-full;
  @apply m-auto;

  @apply max-w-[var(--layout-max-width)];
  @apply grid grid-cols-[var(--sidecolumn-size)_1fr_var(--sidecolumn-size)];

  @apply items-center;
}

.title {
  @apply flex items-center;
  @apply mx-lg xl:pl-0;
}

.userMenuContainer {
  @apply mx-[var(--gutter)];
}
