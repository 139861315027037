.gridForm {
  @apply grid;
  @apply grid-cols-1 gap-y-0 gap-x-0;
}
.infoButton {
  @apply absolute;
  @apply top-0 right-0;
  @apply outline-0;
  @apply mt-1 ml-1;
}
.infoContent {
  @apply absolute;
  @apply top-7 right-3;
  @apply p-5;
  @apply ml-2.5;
  @apply text;
  background-color: var(--color-blue-gray-130);
}
.infoContent dd {
  @apply mb-sm;
}
.businessHandle {
  @apply relative;
}
.businessHandleInput {
  @apply mb-md;
}
.businessHandle .businessHandlePreview {
  @apply relative bottom-xl;
  @apply text text-ellipsis;
  @apply overflow-hidden whitespace-nowrap;
}
.gridForm.twoCol {
  @apply sm:gap-x-4 sm:grid-cols-2;
}

.gridForm > div {
  @apply my-0;
}

@media only screen and (min-width: 640px) {
  .gridForm.twoCol {
    grid-template-rows: auto auto auto auto;
  }
  .gridForm.twoCol > div {
    min-height: 6.5rem
  }
}
