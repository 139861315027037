div.modalWithForm {
  @apply pb-0;
}

.description {
  @apply text-sm;
}

.action {
  @apply mt-5.5;
  @apply min-w-[var(--spacing-40)];
  @apply w-full sm:w-auto;
}
