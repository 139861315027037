.analytics, .analyticsBlock {
  @apply flex flex-col gap-sm;
}

.analytics {
  @apply mt-1.5;
}
  
.analyticsBlock {
  @apply text-xs;
}

.progressBar {
  @apply w-full;
}

.description {
  @apply flex gap-xs;
}

.description a {
  @apply text-brand-primary no-underline;
}

.description a:hover {
  @apply underline;
}

.caps {
  @apply uppercase;
}

.infoIcon {
  @apply mt-0.75 -mb-1.25;
}
