.selectOptionLayout {
  @apply grid grid-cols-2;
}

.planNameIdWrapper {
  @apply place-self-center justify-self-start;
}

.planNameIdWrapper > span {
  @apply block;
}

.planName {
  @apply text;
}

.id {
  @apply uppercase;
  @apply text-secondary;
}

.availableSeats {
  @apply place-self-center justify-self-end;
  @apply text-secondary;
}
