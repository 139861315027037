.tabGroup {
  
}

.tabList {
  @apply whitespace-nowrap overflow-x-hidden flex;
}

.tab {
  @apply border px-2 sm:px-4 py-1;
  @apply sm:leading-4;
  @apply text-center;
  @apply bg-base;
  flex: 1 1 0px;
  border-color: var(--color-background-button-secondary-base);
  color: #909ba6;
}
.tab:first-of-type {
  @apply rounded-l-md rounded-r-none;
}
.tab:last-of-type {
  @apply rounded-r-md rounded-l-none;
}
.tab.selected {
  @apply border py-1 lg:py-2;
  @apply text-accent text-center;
  background: var(--color-background-button-secondary-base);
  border-color: var(--color-background-button-secondary-base);
}
.tab.selected:first-of-type {
  @apply rounded-l-md rounded-r-none;
}
.tab.selected:last-of-type {
  @apply rounded-r-md rounded-l-none;
}

.tabPanels {
  
}
.tabPanel {
  
}
