:root {
  color-scheme: dark;

  --link-color: var(--alias-color-brand-primary);

  --link-bg-color: hsl(var(--hues-blue-hue) 7% 28%);
  --link-hover-bg-color: hsl(var(--hues-blue-hue) 7% 28% / 0.5);
  --link-hover-color: hsl(var(--hues-blue-hue) 7% 100% / 0.5);

  --switch-bg: hsl(var(--hues-blue-hue) 7% 44%);
  --switch-knob-bg: hsl(var(--hues-blue-hue) 7% 95%);

  --active-color: rgb(41, 157, 141);
  --verified-color: #1bc94e;
  --unverified-color: #ffba42;

  --pending-color: #ffba42;
  
  /* Base layout */
  --sidebar-open-width: 13rem;
  --sidecolumn-size: 12rem;
  --topbar-height: 3.875rem;
  --gutter: var(--spacing-3);
  --layout-max-width: 1280px;
  --main-top-margin: calc(var(--topbar-height) + var(--spacing-sm));
}
@media screen and (min-width: 640px) {
  :root {
    --gutter: var(--spacing-4);
  }
}
@media screen and (min-width: 1024px) {
  :root {
    --gutter: var(--spacing-3);
  }
}
@media screen and (min-width: 1280px) {
  :root {
    --gutter: var(--spacing-4);
  }
}

html, body {
  @apply bg-secondary;
  scroll-behavior: smooth;
}

html, body, #root {
  @apply h-full min-h-screen max-h-screen;
}

main {
  @apply overflow-x-visible;
}

/* Temporary (?) override, update indivudual non-bullet lists instead */
ul {
  list-style-position: inherit;
  padding-left: 0;
}
