.deleteLinkedAccount {
  @apply flex justify-end;
}

.social {
  @apply text-center;
  @apply border-b border-weak pb-lg;
}

.socialButtons {
  @apply px-0 sm:px-sm;
}

.businessEmail {
  @apply mt-xl text-center;
}
