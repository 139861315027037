.selectWrapper {
  @apply relative z-40;
}

.select {
  @apply relative text-left;
}
.select > button {
  @apply pr-10;
  @apply w-full;
  @apply text-left justify-start;
  @apply h-auto min-h-[var(--size-button-height)];
  border-color: var(--alias-color-border-weak);
  background-color: var(--color-background-input-default);
}

/* Inline */
.selectWrapper.inline, .selectWrapper.inline .select {
  @apply inline-block w-auto p-0 m-0 h-auto;
}
.selectWrapper.inline .select, .selectWrapper.inline .select button {
  min-width: 10rem;
}

.selectWrapper.inline .select button {
  @apply pr-5;
  color: var(--color-background-button-primary-base);
}

.selectWrapper.inline .select > button, .selectWrapper.inline .select > button:focus {
  @apply border-0 bg-transparent;
}

.selectWrapper.inline .select > button[aria-invalid] {
  @apply border-0 bg-transparent;
  @apply text-danger;
}

/* Dropdown icon */
.select > button .dropDownIcon {
  @apply absolute right-2.5 top-1/2 -mt-2.5;
  @apply z-50;
}

.selectWrapper.inline .select > button .dropDownIcon {
  @apply right-0;
}

.select > button .dropDownIcon {
  @apply text-accent;
}

.select > button .dropDownIcon > svg {
  fill: var(--alias-color-text-accent);
}

.select.discreet > button .dropDownIcon,
.selectWrapper.inline .select > button .dropDownIcon {
  color: var(--color-background-button-primary-base);
}

.select.discreet > button .dropDownIcon > svg,
.selectWrapper.inline .select > button .dropDownIcon > svg {
  fill: var(--color-background-button-primary-base);
}

.select > button:disabled .dropDownIcon > svg {
  @apply opacity-50;
}

.select > button[aria-invalid] .dropDownIcon > svg {
  fill: var(--alias-color-danger)
}

.selectWrapper.locked > .select > button {
  @apply cursor-default;
  @apply pointer-events-none;
}

.selectWrapper.locked > .select > .dropDownIcon  {
  @apply opacity-0;
}

/* Option list */
.selectOptions {
  @apply absolute max-h-64;
  @apply my-1;
  @apply p-1;
  @apply w-full overflow-auto text-base focus:outline-none;
  @apply bg-secondary;
  @apply shadow rounded;
  @apply shadow-dark;
  @apply border border-weak;
  @apply text;
}

.selectWrapper.inline .select .selectOptions {
  @apply my-1;
}

.selectWrapper.inline .select .selectOptions[data-placement="top"] {
  @apply -mb-1;
}

/* Options */
.selectOptions li {
  @apply relative cursor-pointer select-none py-2 px-4;
  @apply text-sm;
  @apply rounded
}

.active, .selected {
  @apply bg-base;
}

.selected:not(.active), .selected:not(.active) span {
  @apply text-accent;
}
