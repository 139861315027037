.topbar {
  @apply w-full py-md px-[var(--gutter)] z-50;
  @apply sticky top-0;
  @apply grid grid-cols-[1fr_2fr_1fr] items-center;

  @apply bg-base;
  @apply text-accent;

  @apply border-b border-b-weak;
}

.userName {
  @apply text-sm;
  @apply overflow-x-hidden whitespace-nowrap text-ellipsis;
}

.title {
  @apply flex justify-center;
  @apply whitespace-nowrap text-ellipsis;
}

.burgerContainer {
  @apply flex justify-end;
}

.burger {
  @apply flex justify-end;
  @apply cursor-pointer;
}

.burger:hover svg path {
  @apply fill-[var(--alias-color-brand-primary)];
}
