.content {
  @apply p-2xl;
  @apply sm:flex sm:grid-cols-2 sm:gap-4 sm:items-stretch;
}

.invitationInfo {
  @apply py-md;
}

.whatIsSpace {
  @apply grow;
}

.whatIsSpaceUl {
  @apply ml-2.5 my-md;
}

.whatIsSpaceLi {
  @apply pl-2;
}

.whatIsSpaceLi::marker {
  @apply text-brand-primary;
  content: '\2713';
}

.spaceInfoWrapper {
  @apply grid place-items-center;
  @apply text-center;
  @apply sm:mt-lg sm:self-center;
}

.invitationAvatar {
  @apply w-14 h-14 sm:w-20 sm:h-20 m-4;
}

.spaceInfoName {
  @apply text-accent font-bold;
  @apply leading-tight;
  @apply sm:max-w-[var(--spacing-28)] overflow-hidden text-ellipsis;
}


