.subscription {
  @apply grid grid-cols-1 gap-5;
}

.license {
  @apply items-center;
  @apply -mt-6 -mx-6 px-xl sm:mt-0 sm:mx-0 sm:px-lg;
  @apply rounded-none sm:rounded-md;
}

@media (min-width: 768px) {
  .subscription {
    grid-template-areas: "hero hero" "features info";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }

  
  .license {
    grid-area: hero;
  }

  .subscription > div:nth-child(2n) {
    grid-area: features;
  }

  .billing {
    grid-area: info;
  }
}

@media (min-width: 1024px) {
  .subscription {
    grid-template-areas: "hero features info";
    grid-template-rows: initial;
    grid-template-columns: 30% repeat(2, minmax(0, 1fr));
  }

  .license {
    @apply md:mr-3;
    @apply self-start;
  }
}

.billing {
  @apply h-full;
}

.billingTitle {
  @apply text-sm uppercase font-semibold lg:mb-1 mb-3;
}

.billingList {
  @apply h-full;
}

.billingList li {
  @apply flex gap-1 mb-2 leading-4;
}

.billingList li > span:first-child {
  @apply self-center;
}

.buttons {
  @apply flex justify-center mt-4;
}
