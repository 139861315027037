.binarySelect ul {
  min-width: 13rem;
}

.downloadButton {
  color: var(--color-font-button-primary-base) !important;
  @apply !pr-2xl;
}

.dropDownIcon {
  @apply absolute right-sm top-1/2 -mt-2.5;
}
.dropDownIcon > svg {
  fill: var(--alias-color-text-accent);
}

.binarySelect ul li {
  @apply overflow-x-hidden overflow-ellipsis whitespace-nowrap;
}

.highlightedOption {
  @apply font-semibold;
}
