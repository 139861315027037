.ssoBanner {
  @apply pt-md pb-2xl;
  @apply relative;
}

.content {
  @apply pr-3xl;
}

.buttonBar {
  @apply border-b;
  @apply px-0 pb-xl pt-0;
}

.closeButton {
  @apply absolute right-md top-0 py-sm;
}

.closeButton:hover svg, .closeButton:active svg {
  @apply fill-brand-primary;
}
