.ssoMessageSection {
  @apply flex flex-wrap content-center;
}
.verifiedIcon {
  @apply mr-1.5 align-text-top;
}
.verifiedText {
  @apply text-ok;
}
.unVerifiedIcon {
  @apply mr-1.5 align-text-top opacity-40;
}
.unVerifiedText {
  @apply text-secondary;
}
.disabled {
  @apply opacity-[var(--opacity-disabled)] pointer-events-none;
}
.enterpriseAccNotification {
  @apply mb-lg;
}
