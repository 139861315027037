.content {
  @apply p-2xl;
}

.error {
  @apply -mb-md text-center;
}

.planInfo {
  @apply flex flex-col p-0; 
  @apply sm:flex-row;
}
.planInfo > div {
  @apply sm:w-6/12;
}
.planInfo > div:first-child {
  @apply mt-1 mb-4;
  @apply sm:mb-0 sm:mr-6;
}
.planInfo > div:first-child > div:nth-child(2n) {
  @apply pt-6;
}
.planInfo > div:first-child > div:nth-child(3n) {
  @apply pb-7;
}
.planInfo > div:last-child {
  @apply px-2;
  @apply sm:px-0 sm:ml-6;
}
