.licenseImage {
  @apply rounded-md;
  @apply text-white;
  @apply py-xl px-lg;
  @apply flex;
}
.licenseImage:global(.personal) {
  background-color: #ca3dce;
  background: linear-gradient(45deg, #8066ce, #ca3dce 50%, #f63e71);
  background: conic-gradient(from 0deg at -5% -5%, #8066ce 90deg, #ca3dce 120deg, #f63e71 180deg);
}
.licenseImage:global(.pro), .licenseImage:global(.enterprise) {
  background-color: #756cce;
  background: linear-gradient(128deg, #3da56d, #756cce 50%, #756bce);
  background: conic-gradient(from 0deg at -5% 105%, #1dcb6e 0deg, #756cce 90deg, #756bce 100deg);
}
.licenseImage:global(.proTrial) {
  background-color: #756cce;
  background: linear-gradient(128deg, #3da56d, #f63e71 50%, #f63e71);
  background: conic-gradient(from 0deg at -5% 105%, #1dcb6e 0deg, #f63e71 90deg, #f63e71 100deg);
}
.licenseImage > svg {
  @apply w-12 h-12 shrink-0 mt-0.5 mr-4;
}
.description {
  @apply -mt-0.25;
}
.description > h3 {
  @apply my-0 text-sm;
  @apply text-base uppercase font-bold;
}
.description > p {
  @apply mb-0 text-xs leading-4;
}
