.header {
  @apply mb-0;
}

.tabs {
  @apply flex gap-md flex-col md:flex-row md:justify-between md:items-baseline;
  @apply my-lg md:mt-sm md:mb-2xl;
}

.tabs > span {
  @apply text-sm;
}

.heading {
  @apply my-sm;
}

.seatInfo {
  @apply mt-lg sm:mt-0;
  @apply uppercase text-secondary;
}

.noSeats {
  @apply flex gap-sm items-center;
}

.seatCount {
  @apply text-accent mr-2;
}
