.catalogBlocks {
  @apply flex flex-col gap-xl;
  @apply md:grid md:grid-cols-3;

  @apply mt-md mb-3xl;
}

.block {
  @apply flex flex-col;
}

.block p {
  @apply grow text-sm;
}

.block a {
  @apply self-start;
}
