.singleSignOn {
  @apply text;
}

.singleSignOn header {
  @apply text text-base;
  @apply lg:text-sm;
  @apply min-w-full mb-8;
}

.singleSignOn header p, .singleSignOn section p {
  @apply leading-normal;
}

.singleSignOn header h2.welcome {
  @apply mt-sm mb-lg;
}

.singleSignOn section {
  @apply mb-14;
}

.disabled {
  @apply opacity-[var(--opacity-disabled)];
  @apply pointer-events-none;
}
