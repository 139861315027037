.layout {
  @apply overflow-y-auto h-full flex flex-col;
  @apply bg-base;
  @apply text;
  @apply h-full;
}
.layout > header {
  @apply p-4;
}
.layout > header h1 {
  @apply flex-shrink-0 flex items-center text-accent max-w-screen-lg m-auto;
  @apply text-base md:text-lg
}
.layout > header h1 img {
  @apply block h-8 w-auto mr-3;
}
.layout > .wrapper {
  @apply flex flex-col max-w-screen-md m-auto;
}
.layout > .wrapper > main {
  @apply flex flex-col;
  @apply w-screen;
}
.sm > .wrapper > main {
  @apply max-w-sm;
}
.md > .wrapper > main {
  @apply max-w-md;
}
.lg > .wrapper > main {
  @apply max-w-lg;
}
.xl > .wrapper > main {
  @apply max-w-screen-sm;
}

.layout > .wrapper > footer {
  @apply mb-2;
}

.layout > .wrapper > footer {
  @apply my-3;
}
.layout > .wrapper > footer > div, .layout > .wrapper > footer > span {
  @apply text-sm my-2 text-center;
  color: var(--color-font-input-default);
}
.layout > .wrapper > footer > div a {
  @apply text-brand-primary;
}


.logoText {
  @apply text-base uppercase -mt-xs leading-[1em] text-accent mb-lg;
}
header.panel {
  @apply mt-0;
}
.header {
  @apply -mb-lg;
}
.withTitle {
  @apply min-h-[var(--spacing-28)] -mb-xl;
}
footer.withPageFooter {
  @apply mb-0;
}
