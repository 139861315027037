.contextMenuHeader {
    @apply flex flex-1 justify-end;
    @apply pr-3;
}
.menuContainer {
    @apply relative;
    @apply flex flex-1 justify-end;
}
.menu {
    @apply absolute top-5 right-5 shadow-lg;
    min-width: 10rem;
    max-width: 18rem;
    @apply border-t-0 rounded-sm;
    @apply bg-base;
    @apply mt-0 p-0;
    max-height: 80vh;
}
.menuItem {
    @apply w-full;
}
.menuItem > button {
    @apply bg-base text-left text-sm rounded-none text;
    @apply w-full whitespace-nowrap;
}

.menuItem > button:first-child {
    @apply rounded-t-sm;
}

.menuItem > button:last-child {
    @apply rounded-b-sm;
}

.menuItem > button:enabled:hover {
    @apply bg-tertiary;
    @apply text-accent;
}

.menuItem > button:disabled:hover {
    @apply bg-base;
}
