.avatarForm {
    @apply text-center;
}

.userAvatarInput {
    display: none;
}
.changeAvatarButton {
    @apply mb-md;
}
