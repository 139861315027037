.pageHeader {
  @apply flex flex-col items-start w-full;
  @apply mb-3;
}

.heading, .ingress {
  @apply flex items-stretch gap-4;
  align-self: stretch;
}

.heading {
  @apply mb-xs;
  @apply text-accent;
}

.heading h2, .ingress p {
  @apply grow;
}

.ingress {
  @apply text-sm;
  color: var(--alias-color-text-primary);
}

.subHeading {
  @apply mb-2;
}
