.scim {
  @apply text-tertiary;
}
.scim > dt {
  @apply mt-md;
}
.scim > dd {
  @apply flex items-center mb-md;
}
.scim > dd > span {
  @apply mr-md;
}
.scim > dd > button {
  @apply flex items-center;
}
.visibilityButton {
  @apply mr-2;
}
