.wrapper {
  @apply mb-1;
}

.autocomplete {
  @apply mb-0 relative;
  @apply pr-12;
  @apply text-ellipsis;
}

.listWrapper {
  @apply h-0 overflow-y-visible relative z-40;
}

.options {
  composes: selectOptions from "src/components/Select/Select.module.css";
  @apply my-0;
  @apply relative;
}

.active, .selected {
  @apply bg-base;
}

.selected:not(.active), .selected:not(.active) span {
  @apply text-accent;
}

.dropDown {
  @apply absolute inset-y-0 right-0 w-10 z-10 rounded-r; 
  @apply flex justify-center items-center;
  @apply border-l;
  border-color: var(--alias-color-border-default);
}
.dropDown[disabled] {
  @apply cursor-default;
}
.dropDown:not([disabled]):hover {
  @apply border;
  border-color: var(--alias-color-brand-primary);
}
.dropDown svg {
  fill: var(--color-font-input-default);
}
.dropDown:not([disabled]):hover svg {
  fill: var(--alias-color-brand-primary);
}
