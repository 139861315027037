.featureManagement section {
  @apply mb-14;
}

.enterpriseAccNotification {
  @apply mb-lg -mt-md;
}

.disabledSection {
  @apply opacity-50;
  @apply pointer-events-none;
}

.form {
  @apply relative;
}
