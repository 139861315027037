.page {
  @apply bg-base text-quarternary;
  font-family: var(--typography-font-primary);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.logo {
  @apply hidden sm:block sm:h-5;
  @apply p-4 pb-0 absolute;
}

.logo span:global(.lds-icon) {
  @apply ml-xxs mr-sm align-text-bottom;
}

.logoText {
  font-size: var(--typography-size-lg);
  @apply inline-flex items-center select-none;
  @apply text-accent;
}
