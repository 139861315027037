/* Payment method */
.paperInvocing {
  @apply flex items-center gap-lg;
  @apply pl-md pr-xs py-lg mb-md;
  @apply border-y;
  min-height: var(--spacing-12);
}

.creditCard {
  @apply flex items-center gap-lg;
  @apply pl-md pr-xs py-lg mb-md;
  @apply border-y;
  min-height: var(--spacing-12);
}

.creditCardDetails {
  @apply grid grid-rows-2 gap-1;
  @apply text text-xs leading-3;
}

.paperInvocingDetails {
  @apply grid gap-1;
  @apply text text-xs leading-3;
}

.paperInvocingDetails strong {
  @apply text-tertiary;
}

.details {
  @apply text-xs;
  @apply grow;
}

.noPaymentMethod {
  @apply grow;
  @apply text-sm text-secondary text-center;
}

/* Table style overrides */
.tableHeader, .tableCell {
  @apply sm:pl-md;
}
.tableHeader {
  @apply py-0;
  @apply sm:py-sm;
}
.tableCell {
  @apply py-4;
  @apply sm:py-xxs;
  @apply text-sm text;
}

/* Subscriptions */
.subscriptionId {
  @apply text font-bold;
}
.status {
  @apply capitalize;
}
.active {
  @apply text-ok;
}
.canceled, .expired, .failed {
  @apply text-danger;
}
.defaultState {
  @apply text-secondary;
}

/* Invoices */
.invoiceId {
  @apply text font-bold;
}

.errorNotification {
  @apply text-sm mb-5;
}

.updatePaymentMethodButton {
  @apply capitalize;
}

.buttonBar {
  @apply sm:w-max px-0;
}

.changeQuantityLayout {
  @apply grid gap-3 items-center justify-center;
  grid-template-columns: 25px 1fr;

}

.quantityText {
  @apply pr-1 sm:pr-0 text-right;
}

.changeButtonBar {
  @apply p-0;
}

.changeButtonBar button {
  @apply m-0;
}
