.subtitle {
  @apply my-md;
}

footer.lensLauncher {
  @apply flex flex-col items-stretch text-center mb-0;
  @apply md:grid md:grid-cols-2 md:gap-x-4 md:items-center md:text-left;
}

footer.lensLauncher p {
  @apply sm:text-right md:mb-0;
}

.launchButton {
  @apply text-brand-primary;
  @apply inline-block ml-xs pr-0;
}

.launchButton button {
  @apply px-0;
}

.lensLauncherImage {
  @apply max-h-96 mx-auto;
}
