.lensID {
  @apply grid text-left;
  grid-template-columns: 3.3rem 1fr;
  max-width: 25rem;
}

.lensIDAvator {
  @apply rounded-full self-center;
}

.lensIDUserBlock {
  @apply self-center;
}

.lensIDUsername {
  @apply text-accent font-bold;
}

.lendIDSubtitle {
  @apply text;
}
